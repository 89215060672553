<template>
  <div class="body_section">
    <profileHeader
      :showFilter="true"
      @changedGlobalclient="changedGlobalclient"
    />
    <div class="body_content" v-if="ClientDataloaded">
      <div class="main_content">
        <div class="questionnaire_details_wrap">
          <div class="questionnaire_details_list" id="ownerdetails">
            <div>
              <div class="questionnaire_details_cnt">
                <h3 class="heading1">
                  {{ checkProperty(clientData, "busName") }}
                </h3>

                <div
                  class="list_table client_table client_table_credentails padt0"
                  v-if="clientData"
                >
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">State</th>
                        <th>Username</th>
                        <th scope="col">Password</th>
                        <th scope="col">Payment Frequency</th>
                        <th scope="col">Payment On</th>
                        <th scope="col">Last Filed Date</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(iden, index) in clientData.stateReg">
                        <tr :key="index">
                          <td>
                            <p>
                              {{ checkProperty(iden, "stateDetails", "name") }}
                            </p>
                          </td>

                          <td>
                            <p v-if="checkProperty(iden, 'userName') != '--'">
                              {{ checkProperty(iden, "userName") }}
                            </p>
                            <p v-else>*****</p>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <p v-if="checkProperty(iden, 'password') != '--'">
                                {{ checkProperty(iden, "password") }}
                              </p>
                              <p v-else>*****</p>
                              <template v-if="iden.hasCredentials">
                                <div
                                  class="eye_icon"
                                  title="show"
                                  @click="getCredentials(iden)"
                                >
                                  <b-icon
                                    icon=" eye-fill"
                                    aria-hidden="true"
                                  ></b-icon>
                                </div>
                              </template>
                              <template v-else>
                                <div
                                  class="eye_icon"
                                  title="hide"
                                  @click="hideCredentials(iden)"
                                >
                                  <b-icon
                                    icon="eye-slash-fill"
                                    aria-hidden="true"
                                  ></b-icon>
                                </div>
                              </template>
                            </div>
                          </td>
                          <td>
                            <p>
                              {{
                                checkProperty(
                                  iden,
                                  "payFrequencyDetails",
                                  "name"
                                )
                              }}
                            </p>
                          </td>
                          <td>
                            <p>{{ checkProperty(iden, "paymentOn") }}</p>
                          </td>
                          <td>
                            <p
                              v-if="
                                checkProperty(iden, 'trxnStartDate') !== '--'
                              "
                            >
                              {{
                                checkProperty(iden, "trxnStartDate")
                                  | formatDate
                              }}
                            </p>
                            <p v-else>--</p>
                          </td>
                          <td @click="showDetails(iden)">
                            <div class="d-flex align-items-center">
                              <a href="#">Edit</a>
                            </div>
                          </td>
                        </tr>
                      </template>
                      <template v-if="clientData.stateReg.length <= 0">
                        <tr>
                          <td class="text-center" colspan="7">No Data</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="transactionModal"
      centered
      hide-footer
      hide-header
      class="modal_popup"
    >
      <div class="modal_popup profile_popup padt40">
        <b-button class="close" block @click="hideDetails"
          ><span aria-hidden="true">&times;</span></b-button
        >

        <form @click="formValidate()" @submit.prevent="updateStateDetails">
          <ValidationObserver ref="statesalreadyregistered">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="form_label">Username </label>
                  <template v-if="!selectedState.hasCredentials">
                    <ValidationProvider
                      :name="'User name' + index"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        v-if="!selectedState.hasCredentials"
                        type="text"
                        class="form-control"
                        :name="'user_name' + index"
                        v-model="selectedState.userName"
                      />
                      <span class="v-messages error-msg"
                        >{{ errors[0] ? "User Name is required" : "" }}
                      </span>
                    </ValidationProvider>
                  </template>
                  <template v-else>
                    <input
                      type="text"
                      :disabled="true"
                      class="form-control"
                      :name="'username' + index"
                      value="*****"
                    />
                  </template>
                </div>
              </div>
              <div class="col view_password_wrap">
                <div class="form-group view_password">
                  <label class="form_label">Password </label>
                  <template v-if="!selectedState.hasCredentials">
                    <ValidationProvider
                      :name="'Username' + index"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :name="'password' + index"
                        v-model="selectedState.password"
                      />
                      <span class="v-messages error-msg"
                        >{{ errors[0] ? "Password is required" : "" }}
                      </span>
                    </ValidationProvider>
                  </template>
                  <template v-else>
                    <input
                      type="text"
                      :disabled="true"
                      class="form-control"
                      :name="'password' + index"
                      value="*****"
                    />
                  </template>
                </div>

                <template v-if="checkProperty(selectedState, '_id')">
                  <template v-if="selectedState.hasCredentials">
                    <div
                      class="eye_icon mart"
                      title="show"
                      @click="getCredentials(selectedState)"
                    >
                      <!-- <b-icon icon="" aria-hidden="true"></b-icon> -->
                      <b-icon icon=" eye-fill" aria-hidden="true"></b-icon>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="eye_icon mart"
                      title="hide"
                      @click="hideCredentials(selectedState)"
                    >
                      <b-icon icon="eye-slash-fill" aria-hidden="true"></b-icon>
                    </div>
                  </template>
                </template>
              </div>
            </div>
            <!---- {{selectedState}}-->
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="form_label">State</label>

                  <ValidationProvider
                    :name="'State'"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      v-model="selectedState.stateDetails"
                      :value="'_id'"
                      :options="usstateList"
                      :searchable="true"
                      :close-on-select="true"
                      :allow-empty="false"
                      :multiple="false"
                      label="name"
                      placeholder="Select "
                      track-by="name"
                    >
                    </multiselect>

                    <span class="v-messages error-msg">{{
                      errors[0] ? "Field is required" : ""
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="form_label">Estimated Sales</label>
                  <ValidationProvider
                    :name="'Estimated Sales' + index"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="number"
                      class="form-control"
                      :name="'Estimated Sales' + index"
                      v-model="selectedState.estimatedSales"
                    />
                    <span class="v-messages error-msg"
                      >{{ errors[0] ? "Estimated Sales is required" : "" }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="form_label">Payment Frequency</label>

                  <multiselect
                    v-model="selectedState.payFrequencyDetails"
                    :value="'_id'"
                    :options="pay_frequency"
                    :searchable="true"
                    :close-on-select="true"
                    :allow-empty="false"
                    :multiple="false"
                    label="name"
                    placeholder="Select Payment Frequency"
                    track-by="name"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="form_label">Payment On </label>
                  <ValidationProvider
                    :name="'PaymentOn' + index"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="number"
                      class="form-control"
                      :name="'PaymentOn' + index"
                      v-model="selectedState.paymentOn"
                    />
                    <span class="v-messages error-msg"
                      >{{ errors[0] ? "Payment On is required" : "" }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="input-group date" data-date-format="dd-mm-yyyy">
                    <label class="form_label">Last Calculated Date</label>
                    <!-- :disabled="checkProperty(selectedState ,'trxnStartDate')!='--' " --->
                    <ValidationProvider
                      :name="'trxnStartDate' + index"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <datepicker
                        :disabledDates="disabledDates"
                        :max-date="new Date()"
                        :required="true"
                        :format="customFormatter"
                        v-model="selectedState.trxnStartDate"
                        name="uniquename"
                      ></datepicker>

                      <span class="input-group-addon"
                        ><i class="glyphicon glyphicon-calendar"></i
                      ></span>
                      <span class="v-messages error-msg">{{
                        errors[0] ? "Last Calculated Date is Required" : ""
                      }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group marb10">
                  <label class="form_label">Sales Tax Permit</label>
                  <div class="upload_sec">
                    <uploadFile
                      @onUploadComplete="onUploadComplete"
                      :allowMultiple="true"
                      :acceptedFiles="'*'"
                      :takeIndex="false"
                      :payLoadKey="'selectedState'"
                      :payLoadDocumentsKey="'documents'"
                    />
                  </div>

                  <ul class="uploaded-list">
                    <template v-for="(other, indx) in selectedState.documents">
                      <li :key="indx">
                        {{ other.name }}
                        <span @click="removeDoc(indx, selectedState.documents)"
                          >X</span
                        >
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input
                  class="styled-checkbox"
                  :id="'operating' + index"
                  @click="isOperating($event, index)"
                  v-model="selectedState.operating"
                  type="checkbox"
                  :value="selectedState.operating"
                />
                <label :for="'operating' + index">In operating</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button class="primary_btn" :disabled="!validForm">
                  Update
                </button>
              </div>
            </div>
          </ValidationObserver>
        </form>
      </div>
    </b-modal>

    <div v-if="showLoading" class="page_loader">
      <img src="@/assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import uploadFile from "@/components/uploadFile.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import profileHeader from "@/components/ProfileHeader.vue";
import _ from "lodash";
import moment from "moment";
//import uploadImage from "@/assets/images/upload_icon.svg"
import Multiselect from "vue-multiselect-inv";
import Datepicker from "vuejs-datepicker";
export default {
  name: "questionnaire",
  watch: {},
  mounted() {
    this.getMasterData("state");
    this.getMasterData("pay_frequency");

    this.togleBlock();
    if (_.has(this.userdata, "clientDetails")) {
      this.Payload.clientId = this.userdata.clientDetails._id;
    } else if (_.has(this.$route.params, "clientId")) {
      this.Payload.clientId = this.$route.params["clientId"];
    }
    this.getClientdetails();
  },

  components: {
    uploadFile,
    Multiselect,
    profileHeader,
    ValidationObserver,
    ValidationProvider,
    Datepicker,
  },
  data() {
    return {
      validForm: false,
      disabledDates: {
        from: new Date(),
      },
      index: 0,
      selectedState: {
        _id: "", // Required in update state registration
        stateId: "", //dropdown
        estimatedSales: "", //number input
        payFrequencyId: "", /// master Drop down
        payFrequencyDetails: {},
        paymentOn: "", //number input // Day Number
        documents: [],
        userName: "",
        password: "",
        operating: true, // check box
        status: true, //pass false while delete stateREG,
        trxnStartDate: "",
      },
      documents: [],
      showDiv: true,
      mailingAddressAsSame: false,

      show_block: 1,

      blocks: {
        form1: {
          active: true,
          validator: "legal_informationForm",
          label: "Legal Information",
          formIsValied: false,
        },
        form2: {
          active: false,
          validator: "business_information",
          label: "Business Information",
          formIsValied: false,
        },
        form3: {
          active: false,
          validator: "owner_details",
          label: "Owner Details",
          formIsValied: false,
        },
        form4: {
          active: false,
          validator: "statesalreadyregistered",
          label: "States Registered",
          formIsValied: false,
        },
      },

      clientData: null,
      Payload: {
        clientId: "",
        busContact: {
          fName: "Thamous",
          lName: "N",
          phone: "",
          phoneCode: "",
          email: "",
        },
        busName: "",
        tradeName: "",
        busAddress: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: 101,
        },
        mailingAddress: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: 101,
        },
        taxRecordLocation: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: 101,
        },
        fedaralEmpId: "",
        busFormatedOn: "2000-03-01",
        busStructureId: "", /// master
        busStructureDetails: null,
        incorporatedState: "", /// master
        incorporatedStateDetails: null,
        secretaryStateNumber: "",
        LLCID: "423423", //Doubt
        llcTypeIds: [],
        accMethodId: 2, /// master
        accMethodDetails: null,
        accClosedOn: "2020-10-10",
        nexusTypeId: "", /// master
        nexusTypeDetails: null,
        itemDescription: "",
        NAICSCode: "",
        busTypeId: "", /// master
        businessTypeDetails: null,

        havePhysicalStore: false,
        sellingPlatformIds: [], /// master
        platformDetails: null,
        marketPlaceFecilitator: true,
        sellOnMarketPlace: false,
        purchaseExistingBusiness: false,
        haveEmployeesOrAgents: true,
        otherBusStateIds: [], /// master
        otherBusStates: null,
        website: "",
        articlesOfOrgAndDriverLiecenses: [],
        banks: [],
        bankDetails: [],
        supplier: [
          {
            name: "",
            addr1: "",
            addr2: "",
            stateId: "",
            cityId: "",
            zip: "",
            cntryId: 231,
            phone: "",
            phoneCode: "",
          },
        ],
        stateRegs: null,

        owners: [
          {
            name: "",
            title: "",
            titleEffDate: "",
            addr1: "",
            addr2: "",
            stateId: "",
            cityId: "",
            zip: "",
            cntryId: "",
            phone: "",
            phoneCode: "",
            email: "",
            dateOfBirth: "",
            SSN: "",
            sharePercent: "",
            driverLicenseNo: "",
            licensedState: "", // satateId of us Country
          },
        ],
        stateReg: [
          /*
            {

"_id":"", // Required in update state registration

"stateId": '', //dropdown

"estimatedSales": '', //number input

"payFrequencyId": '', /// master Drop down

"paymentOn": '',  number input // Day Number

"documents": [],

"userName": "",

"password": "",

"operating": true, // check box

"status": true //pass false while delete stateREG
        }*/
        ],
        identifiers: [
          {
            _id: "", // Required in update state identifier
            marketPlace: 1, //master Data
            identifier: "",
          },
        ],
        logo: "",
        //"profCompleted": "Yes" // Send 'Yes' on final submission
      },
      llc_type: [],
      bus_structure: [],
      bus_type: [],
      nexus: [],
      platform: [],
      pay_frequency: [],
      acc_method: [],
      usstateList: [],
      bank: [],
      usCountry: {
        _id: 231,
        sName: "US",
        name: "United States",
        phoneCode: 1,
        curSymbol: "$",
        curCode: "USD",
        zipLen: 5,
      },
      phoneCode: null,
      phone: null,
      ClientDataloaded: true,
    };
  },
  methods: {
    formValidate() {
      this.$refs["statesalreadyregistered"]
        .validate()
        .then((res) => {
          this.validForm = res;
        })
        .catch((err) => {
          console.log(err);
          this.validForm = false;
        });
    },
    updateStateDetails() {
      console.log(this.selectedState);
      this.$refs["statesalreadyregistered"]
        .validate()
        .then((res) => {
          console.log(res);
          this.selectedState.stateId = this.selectedState.stateDetails["_id"];
          this.selectedState.payFrequencyId = this.selectedState.payFrequencyDetails[
            "_id"
          ];
          this.selectedState.trxnStartDate = this.customFormatter(
            this.selectedState.trxnStartDate
          );
          //  this.selectedState = Object.assign(this.selectedState,{clientId:this.Payload.clientId});
          // this.selectedState = Object.assign(this.selectedState,{matrixId:this.selectedState['_id']});
          let postData = {
            matrixData: this.selectedState,
            clientId: this.selectedState.clientId,
            matrixId: this.selectedState.matrixId,
          };

          this.$store
            .dispatch("updateState", postData)
            .then((res) => {
              console.log(res);
              this.ClientDataloaded = false;
              this.ClientDataloaded = true;

              this.notify({
                message: res.data.result.message,
                title: "Success",
                type: "success",
              });
              this.getClientdetails(true);
              this.ClientDataloaded = true;
            })
            .catch((error) => {
              this.notify({ message: error, title: "Error", type: "error" });
            });
        })
        .catch((err) => {
          console.log(err);
          this.validForm = false;
        });
    },
    customFormatter(date) {
      //stateReg.trxnStartDate

      return moment(date).format("yyyy-MM-DD"); //('yyyy-MM-dd');
    },
    getMasterData(category = "state") {
      let payLoad = {
        matcher: {
          searchString: "",
          //"cntryId": 101,
          //"stateId": 665,
          cntryIds: [], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: category, // "state", // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      if (category == "state") {
        payLoad.matcher.cntryIds = [231];
      }

      this.$store.dispatch("getMaterData", payLoad).then((res) => {
        if (category == "state") {
          this.usstateList = res.list;
        }
        if (category == "pay_frequency") {
          this.pay_frequency = res.list;
        }
      });
    },
    onUploadComplete(data) {
      this.log(data);
      //selectedState.documents
      if (_.has(data, "finalDocuments") && data.finalDocuments.length > 0) {
        _.forEach(data.finalDocuments, (doc) => {
          this.selectedState.documents.push(doc);
        });
      }
    },
    removeDoc(index, model) {
      model.splice(index, 1);
    },
    hideDetails() {
      this.selectedState = null;
      this.$refs["transactionModal"].hide();
    },
    showDetails(item) {
      this.selectedState = _.cloneDeep(item);
      this.$refs["transactionModal"].show();
      this.selectedState = Object.assign(this.selectedState, {
        clientId: this.Payload.clientId,
      });
      this.selectedState = Object.assign(this.selectedState, {
        matrixId: item["_id"],
      });
      this.selectedState.hasCredentials = true;
      this.getCredentials(this.selectedState);
      this.formValidate();
    },
    changedGlobalclient(cl) {
      if (
        cl != null &&
        _.has(cl, "_id") &&
        cl["_id"] != null &&
        cl["_id"] != "null" &&
        this.Payload.clientId != cl["_id"]
      ) {
        this.Payload.clientId = cl["_id"];

        // alert(this.Payload.clientId +"===="+ cl["_id"])
        //this.$router.go('/client-details/'+cl["_id"]);
      } else if (_.has(this.userdata, "clientDetails")) {
        this.Payload.clientId = this.userdata.clientDetails._id;
      } else if (_.has(this.$route.params, "clientId")) {
        this.Payload.clientId = this.$route.params["clientId"];
      }
      this.getClientdetails();
    },
    generateData() {
      this.$store
        .dispatch("getList", {
          data: {
            clients: [this.Payload.clientId],
          },
          path: "/transactions/create",
        })
        .then((response) => {
          console.log(response);
          this.$store.dispatch("getList", {
            data: {
              clientId: this.Payload.clientId,
            },
            path: "/filings/migrate",
          });
          ///filings/migrate
        })
        .catch((err) => {
          this.log(err);
        });
    },
    getProperty(value = "", array_obj) {
      if (value != "" && array_obj) {
        let obj = _.find(array_obj, {
          _id: value,
        });
        if (obj && _.has(obj, "name")) {
          return obj["name"];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    hideCredentials(itemModel) {
      //this.log(itemModel);
      itemModel["hasCredentials"] = true;
      itemModel.userName = "*****";
      itemModel.password = "*****";
    },
    getCredentials(itemModel) {
      if (
        _.has(itemModel, "_id") &&
        itemModel._id != "" &&
        itemModel._id != undefined &&
        itemModel._id != null
      ) {
        this.$store
          .dispatch("getCredentials", {
            clientId: this.Payload.clientId,
            matrixId: itemModel._id,
          })
          .then((res) => {
            // alert(JSON.stringify(res));
            itemModel.userName = _.cloneDeep(res.userName);
            itemModel.password = _.cloneDeep(res.password);
            itemModel.hasCredentials = false;
          })
          .catch((err) => {
            console.log(err);
            itemModel.userName = "*****";
            itemModel.password = "*****";
          });
      } else {
        itemModel.userName = "*****";
        itemModel.password = "*****";
      }
    },

    updateNexusTypeDetails(item) {
      if (_.has(item, "_id")) {
        this.Payload.nexusTypeId = item._id;
      } else {
        this.Payload.nexusTypeId = "";
      }
    },

    updateIncorporatedState(item) {
      this.Payload.articlesOfOrgAndDriverLiecenses = [];
      if (item && _.has(item, "_id")) {
        this.Payload.incorporatedState = item._id;
      } else {
        this.Payload.incorporatedStateDetails = null;
        this.Payload.incorporatedState = "";
      }
    },

    selectedAccount(method) {
      _.forEach(this.acc_method, (obj, index) => {
        this.acc_method[index]["selected"] = false;
        if (obj["_id"] == method["_id"]) {
          this.acc_method[index]["selected"] = true;
        }
      });

      this.Payload.accMethodDetails = method;
      this.Payload.accMethodId = method._id;
    },

    togleBlock() {
      let index = 1;
      _.forEach(this.blocks, (item, key) => {
        //this.log(key ,item);

        if (this.show_block == index) {
          this.blocks[key].active = false;
          this.blocks[key].active = true;
          item.active = false;
          item.active = true;
        } else {
          this.blocks[key].active = false;
          item.active = false;
        }
        index = index + 1;
      });
    },

    getClientdetails(hidemodel = false) {
      this.ClientDataloaded = false;
      this.$store
        .dispatch("getClientdetails", {
          clientId: this.Payload.clientId,
        })
        .then((res) => {
          this.clientData = res;
          //this.Payload =res;
          this.Payload["busContact"]["fName"] = this.userdata["fName"];
          this.Payload["busContact"]["fName"] = this.userdata["fName"];
          this.Payload["busContact"]["phone"] = this.userdata["phone"];
          this.Payload["busContact"]["phoneCode"] = this.userdata["phoneCode"];
          this.processData();

          this.togleBlock();
          this.ClientDataloaded = true;
          if (hidemodel) {
            this.$refs["transactionModal"].show();
            this.$refs["transactionModal"].hide();
          }
        })
        .catch((err) => {
          this.log(err);
          this.ClientDataloaded = true;
        });
    },
    processData() {
      //Legal Information
      this.ClientDataloaded = false;

      this.Payload.taxRecordLocation = this.clientData.taxRecordLocation;
      this.Payload.busName = this.clientData.busName;
      this.Payload.tradeName = this.clientData.tradeName;
      this.Payload.logo = this.clientData.logo;

      //Business Information
      this.Payload.busContact = this.clientData.busContact;
      this.Payload.fedaralEmpId = this.clientData.fedaralEmpId;
      this.Payload.busFormatedOn = moment(this.clientData.busFormatedOn).format(
        "YYYY-MM-DD"
      );
      this.Payload.busStructureId = this.clientData.busStructureId;
      this.Payload.busStructureDetails = this.clientData.busStructureDetails;
      this.Payload.secretaryStateNumber = this.clientData.secretaryStateNumber;
      if (_.has(this.clientData, "incorporatedState")) {
        this.Payload.incorporatedState = this.clientData.incorporatedState;
        this.Payload.incorporatedStateDetails = _.find(this.usstateList, {
          _id: this.Payload.incorporatedState,
        });

        //alert(this.Payload.incorporatedStateDetails);
        if (this.Payload.incorporatedStateDetails)
          this.updateIncorporatedState(this.Payload.incorporatedStateDetails);
      }

      if (_.has(this.clientData, "articlesOfOrgAndDriverLiecenses")) {
        this.Payload.articlesOfOrgAndDriverLiecenses = this.clientData.articlesOfOrgAndDriverLiecenses;
      }
      //Payload.llcTypeIds
      if (_.has(this.clientData, "llcTypeIds")) {
        this.Payload.llcTypeIds = this.clientData.llcTypeIds;
        _.forEach(this.llc_type, (obj) => {
          if (this.Payload.llcTypeIds.indexOf(obj._id) > -1) {
            obj.selected = true;
          }
        });
      }
      //accMethodDetails // accMethodId
      if (_.has(this.clientData, "accMethodDetails")) {
        this.Payload.accMethodDetails = this.clientData["accMethodDetails"];
        this.selectedAccount(this.clientData["accMethodDetails"]);
      }

      if (_.has(this.clientData, "accClosedOn")) {
        this.Payload.accClosedOn = moment(
          this.clientData["accClosedOn"]
        ).format("YYYY-MM-DD");
      }

      if (_.has(this.clientData, "nexusTypeDetails")) {
        this.Payload.nexusTypeDetails = this.clientData["nexusTypeDetails"];
        this.updateNexusTypeDetails(this.Payload.nexusTypeDetails);
      }

      //itemDescription
      if (_.has(this.clientData, "itemDescription")) {
        this.Payload.itemDescription = this.clientData["itemDescription"];
      }
      //NAICSCode
      if (_.has(this.clientData, "NAICSCode")) {
        this.Payload.NAICSCode = this.clientData["NAICSCode"];
      }
      if (_.has(this.clientData, "busTypeId")) {
        this.Payload.busTypeId = this.clientData["busTypeId"];
      }

      if (_.has(this.clientData, "businessTypeDetails")) {
        this.Payload.businessTypeDetails = this.clientData[
          "businessTypeDetails"
        ];
      }

      // busTypeId

      if (_.has(this.clientData, "sellingPlatformIds")) {
        this.Payload.sellingPlatformIds = this.clientData["sellingPlatformIds"];
        if (this.Payload.sellingPlatformIds.length > 0) {
          this.Payload.platformDetails = [];
          _.forEach(this.platform, (item) => {
            if (this.Payload.sellingPlatformIds.indexOf(item._id) > -1) {
              this.Payload.platformDetails.push(item);
            }
          });
        }
      }
      //identifiers
      if (_.has(this.clientData, "identifiers")) {
        this.Payload.identifiers = this.clientData.identifiers;
      }

      //"marketPlaceFecilitator": true, "sellOnMarketPlace": false, "purchaseExistingBusiness": false,
      if (_.has(this.clientData, "marketPlaceFecilitator")) {
        this.Payload.marketPlaceFecilitator = this.clientData.marketPlaceFecilitator;
      }
      if (_.has(this.clientData, "sellOnMarketPlace")) {
        this.Payload.sellOnMarketPlace = this.clientData.sellOnMarketPlace;
      }

      /* need to discuss with satish
             ///If So, Please List
            if(_.has(this.clientData ,"sellOnMarketPlace")){
                    this.Payload.sellOnMarketPlace = this.clientData.sellOnMarketPlace;
                }
            /*/

      if (_.has(this.clientData, "purchaseExistingBusiness")) {
        this.Payload.purchaseExistingBusiness = this.clientData.purchaseExistingBusiness;
      }
      if (_.has(this.clientData, "haveEmployeesOrAgents")) {
        this.Payload.haveEmployeesOrAgents = this.clientData.haveEmployeesOrAgents;
      }

      //otherBusStates
      if (_.has(this.clientData, "otherBusStates")) {
        this.Payload.otherBusStates = this.clientData["otherBusStates"];
      }

      if (_.has(this.clientData, "website")) {
        this.Payload.website = this.clientData.website;
      }

      if (_.has(this.clientData, "banks")) {
        this.Payload.banks = this.clientData["banks"];
        this.Payload.bankDetails = [];

        if (this.Payload.banks.length > 0) {
          _.forEach(this.Payload.banks, (b, index) => {
            let bank = _.find(this.bank, {
              _id: b.id,
            });

            if (bank) {
              bank["selected"] = true;
              this.Payload.bankDetails.push(bank);
              this.Payload.banks[index]["name"] = bank["name"];
            }
          });
        }
      }

      if (_.has(this.clientData, "supplier")) {
        this.ClientDataloaded = false;
        let sup = this.clientData["supplier"];
        this.Payload.supplier = [];
        _.forEach(sup, (item) => {
          this.Payload.supplier.push(item);
        });
      }
      ///owners

      if (_.has(this.clientData, "owners")) {
        this.ClientDataloaded = false;
        let sup = this.clientData["owners"];
        this.Payload.owners = [];
        _.forEach(sup, (item) => {
          this.Payload.owners.push(item);
        });

        this.ClientDataloaded = true;
      }

      //stateRegistrations
      ///owners

      if (_.has(this.clientData, "stateReg")) {
        this.ClientDataloaded = false;
        let sup = _.cloneDeep(this.clientData["stateReg"]);
        this.Payload.stateReg = [];
        this.Payload.stateRegs = [];
        _.forEach(sup, (item) => {
          let state = _.find(this.usstateList, {
            _id: item.stateId,
          });

          if (state) {
            this.Payload.stateRegs.push(state);
          }
          item["show"] = true;
          item["status"] = true;
          this.Payload.stateReg.push(item);
        });
        this.log(JSON.stringify(this.Payload.stateRegs));

        this.ClientDataloaded = true;
      }

      if (_.has(this.clientData, "logo")) {
        this.Payload.logo = this.clientData["logo"];
      }

      if (_.has(this.clientData, "havePhysicalStore")) {
        this.Payload.havePhysicalStore = this.clientData["havePhysicalStore"];
      }

      this.ClientDataloaded = true;
    },
  },
  computed: {
    showLoading: {
      // return this.$store.getters["isloading"];
      //return false;
      get() {
        return this.$store.getters["isloading"];
      },
      set(value) {
        this.value = value;
      },
    },
  },
  beforeCreate() {
    //console.log(this.showLoading);
  },
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
};
</script>
